import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import OurRoutes from './Routes';
import * as Routers from './Routers';
import Loader from '../components/container/Loader/Loader'

function AppRouter() {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* website pages */}
          <Route path={OurRoutes.WEBSITE_DEMO} element={<Routers.Demo />} />

          <Route path={OurRoutes.WEBSITE_LOAN_APPLY} element={<Routers.LoanApply />} />

          <Route path={OurRoutes.WEBSITE_HOME} element={<Routers.WebsiteHomePage />} />
          <Route path={OurRoutes.WEBSITE_PRIVACY_POLICY} element={<Routers.WebsitePrivacyPolicy />} />
          <Route path={OurRoutes.WEBSITE_COMMUNITY} element={<Routers.WebsiteCommunityPage />} />
          <Route path={OurRoutes.WEBSITE_MERCHANT} element={<Routers.WebsiteMerchantPage />} />
          <Route path={OurRoutes.WEBSITE_ABOUT} element={<Routers.WebsiteAboutPage />} />
          <Route path={OurRoutes.WEBSITE_LENDERS} element={<Routers.WebsiteLendersPage />} />
          <Route path={OurRoutes.WEBSITE_CONTACTUS} element={<Routers.WebsiteContactPage />} />
          <Route path={OurRoutes.WEBSITE_DIGITAL_ONBOARDING} element={<Routers.DigitalOnboarding />} />
          <Route path={OurRoutes.WEBSITE_LOAN_ORIGINATION} element={<Routers.LoanOrgination />} />
          <Route path={OurRoutes.WEBSITE_FRAUD_PREVENTION} element={<Routers.FraudPrevention />} />
          <Route path={OurRoutes.WEBSITE_UNDERWRITTING} element={<Routers.UnderWritting />} />
          <Route path={OurRoutes.WEBSITE_SERVICING} element={<Routers.Loanservicing />} />
          <Route path={OurRoutes.WEBSITE_RISKMANAGEMENT} element={<Routers.Riskmanagemnet />} />
          <Route path={OurRoutes.WEBSITE_FINTECH} element={<Routers.FINTECH_PAGE />} />
          <Route path={OurRoutes.WEBSITE_MICRO} element={<Routers.MICRO_PAGE />} />
          <Route path={OurRoutes.WEBSITE_NBFC} element={<Routers.NBFC_PAGE />} />
          <Route path={OurRoutes.WEBSITE_STARTUP} element={<Routers.STARTUP_PAGE />} />
          <Route path={OurRoutes.WEBSITE_MERCHANT_CASH} element={<Routers.MERCHANT_CASH />} />
          <Route path={OurRoutes.WEBSITE_PEER} element={<Routers.PEER_TO_PEER />} />
          <Route path={OurRoutes.WEBSITE_KYC} element={<Routers.EKYC />} />
          <Route path={OurRoutes.WEBSITE_CIBIL} element={<Routers.CIBIL />} />
          <Route path={OurRoutes.WEBSITE_BANK_STATEMENT_ANALYSIS} element={<Routers.WEBSITE_BANK_STATEMENT_ANALYSIS />} />
          <Route path={OurRoutes.FINBIT} element={<Routers.Finbit_Page />} />

          {/* Amits route  */}
          <Route path={OurRoutes.WEBSITE_NEO_BANKING} element={<Routers.NEO_BANKING />} />
          <Route path={OurRoutes.WEBSITE_SOLE_LENDERS} element={<Routers.SOLE_LENDERS />} />
          <Route path={OurRoutes.WEBSITE_DEBT_COLLECTION} element={<Routers.DEBT_COLLECTION />} />
          <Route path={OurRoutes.WEBSITE_LOAN_MANAGEMENT_SYSTEM} element={<Routers.LOAN_MANAGEMENT_SYSTEM />} />
          <Route path={OurRoutes.WEBSITE_WORKFLOW_AUTOMATIONS} element={<Routers.WORKFLOW_AUTOMATIONS />} />
          <Route path={OurRoutes.WEBSITE_ESIGN_AND_EMANDATE_SOLUTIONS} element={<Routers.ESIGN_AND_EMANDATE_SOLUTIONS />} />
          <Route path={OurRoutes.WEBSITE_BUSINESS_INTELLIGENCE_SUITE} element={<Routers.BUSINESS_INTELLIGENCE_SUITE />} />
          <Route path={OurRoutes.WEBSITE_CORRESPONDENT_LENDING_SOLUTIONS} element={<Routers.CORRESPONDENT_LENDING_SOLUTIONS />} />
          <Route path={OurRoutes.WEBSITE_CAPITIVE_FINANCE} element={<Routers.CAPiTIVE_FINANCING />} />

          <Route path={OurRoutes.WEBSITE_INVOICE_FINANCE} element={<Routers.INVOICE_FINANCING />} />
          <Route path={OurRoutes.WEBSITE_TRAVEL_AGENCIES} element={<Routers.TRAVEL_AGENCIES />} />
          <Route path={OurRoutes.WEBSITE_POS_LENDING} element={<Routers.POS_LENDING />} />
          <Route path={OurRoutes.WEBSITE_RESTURANTS_HOTEL} element={<Routers.RESTURANTS_HOTEL />} />
          <Route path={OurRoutes.WEBSITE_RETAIL} element={<Routers.RETAIL />} />
          <Route path={OurRoutes.WEBSITE_MEDICAL} element={<Routers.MEDICAL />} />
          <Route path={OurRoutes.WEBSITE_BNPL} element={<Routers.BNPL />} />
          <Route path={OurRoutes.WEBSITE_ECOMMERCE_FINANCING} element={<Routers.ECOMMERCE_FINANCING />} />
          <Route path={OurRoutes.WEBSITE_AUTO_FINANCING} element={<Routers.AUTO_FINANCING />} />
          <Route path={OurRoutes.WEBSITE_EDUCATION_LOAN} element={<Routers.EDUCATION_LOAN />} />
          <Route path={OurRoutes.WEBSITE_OVERDRAFT_FINANCING} element={<Routers.OVERDRAFT_FINANCING />} />

          <Route path={OurRoutes.WEBSITE_COMMUNICATION_VIA_WHATSAPP} element={<Routers.COMMUNICATION_VIA_WHATSAPP />} />
          <Route path={OurRoutes.WEBSITE_IVR_INTEGRATION} element={<Routers.IVR_INTEGRATION />} />
          <Route path={OurRoutes.WEBSITE_PRODUCT_CONFIGURATION} element={<Routers.PRODUCT_CONFIGURATION />} />
          <Route path={OurRoutes.WEBSITE_LEAD_MANAGEMENT} element={<Routers.LEAD_MANAGEMENT />} />
          <Route path={OurRoutes.WEBSITE_HOME_LOAN} element={<Routers.HOME_LOAN />} />
          <Route path={OurRoutes.WEBSITE_LAP_LOAN} element={<Routers.LAP_LOAN />} />
          <Route path={OurRoutes.WEBSITE_PERSONAL_LOAN} element={<Routers.PERSONAL_LOAN />} />
          <Route path={OurRoutes.WEBSITE_OVERDRAFT_LOAN} element={<Routers.OVERDRAFT_LOAN />} />
          <Route path={OurRoutes.WEBSITE_BUSINESS_LOAN} element={<Routers.BUSINESS_LOAN />} />
          <Route path={OurRoutes.WEBSITE_SHORT_TERM_LOAN} element={<Routers.SHORT_TERM_LOAN />} />
          <Route path={OurRoutes.WEBSITE_LOCATE_US} element={<Routers.LOCATE_US />} />
          <Route path={OurRoutes.WEBSITE_SOLUTIONS} element={<Routers.SOLUTIONS />} />
          <Route path={OurRoutes.WEBSITE_MARKET_PLACE_NEW} element={<Routers.Market_place />} />
          <Route path={OurRoutes.WEBSITE_BORROWERS} element={<Routers.Borrowers />} />
          <Route path={OurRoutes.WEBSITE_VEHICLE} element={<Routers.WEBSITE_VEHICLE />} />
          <Route path={OurRoutes.WEBSITE_BANKPAGE} element={<Routers.WEBSITE_BANKPAGE />} />
          <Route path={OurRoutes.WEBSITE_BANKPAGE_DETAILS} element={<Routers.WEBSITE_BANKPAGE_DETAILS />} />
          <Route path={OurRoutes.WEBSITE_PRODUCT_DETAILS} element={<Routers.WEBSITE_PRODUCT_DETAILS />} />
          <Route path={OurRoutes.WEBSITE_BUSINESS} element={<Routers.WEBSITE_BUSINESS />} />
          <Route path={OurRoutes.WEBSITE_RETAIL_NEW} element={<Routers.WEBSITE_RETAIL_NEW />} />
          <Route path={OurRoutes.WEBSITE_INFRA} element={<Routers.WEBSITE_INFRA />} />
          <Route path={OurRoutes.WEBSITE_MOBILITY} element={<Routers.WEBSITE_MOBILITY />} />
          <Route path={OurRoutes.WEBSITE_INVOICEFINANCE} element={<Routers.WEBSITE_INVOICEFINANCE />} />
          <Route path={OurRoutes.WEBSITE_INVOICEFACTORING} element={<Routers.WEBSITE_INVOICEFACTORING />} />
          <Route path={OurRoutes.WEBSITE_INVOICEDISCOUNTING} element={<Routers.WEBSITE_INVOICEDISCOUNTING />} />
          <Route path={OurRoutes.WEBSITE_EVLOAN} element={<Routers.WEBSITE_EVLOAN />} />
          <Route path={OurRoutes.WEBSITE_WORK_CAPITAL_LOAN} element={<Routers.WEBSITE_WORK_CAPITAL_LOAN />} />
          <Route path={OurRoutes.WEBSITE_PLANT_MACHINARY} element={<Routers.WEBSITE_PLANT_MACHINARY />} />
          <Route path={OurRoutes.WEBSITE_TRADE_FINANCE} element={<Routers.WEBSITE_TRADE_FINANCE />} />
          <Route path={OurRoutes.WEBSITE_LETTER_OF_CREDIT} element={<Routers.WEBSITE_LETTER_OF_CREDIT />} />
          <Route path={OurRoutes.WEBSITE_BANK_GUARANTEE} element={<Routers.WEBSITE_BANK_GUARANTEE />} />
          <Route path={OurRoutes.WEBSITE_Bill_DISCOUNTING} element={<Routers.WEBSITE_Bill_DISCOUNTING />} />
          <Route path={OurRoutes.WEBSITE_PROJECT_APPROVAL} element={<Routers.WEBSITE_PROJECT_APPROVAL />} />
          <Route path={OurRoutes.WEBSITE_PROJECT_FINANCING} element={<Routers.WEBSITE_PROJECT_FINANCING />} />
          <Route path={OurRoutes.WEBSITE_B2C} element={<Routers.WEBSITE_B2C />} />



          {/* End */}
          <Route path={OurRoutes.ESIGN} element={<Routers.EsignPage />} />
          <Route path={OurRoutes.ENACH} element={<Routers.EnachPage />} />


          {/* super admin pages */}

          <Route path={OurRoutes.SUPER_ADMIN_LOGIN} element={<Routers.SuperAdminLoginPage />} />
          <Route element={<Routers.SuperAdminDashboardLayoutPage />}>
            <Route path={OurRoutes.ADMIN_BULK_COMMUNICATION} element={<Routers.BulkCommunicationPage />} />

            <Route path={OurRoutes.SUPER_ADMIN_DASHBOARD} element={<Routers.SuperAdminDashboardPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_PRODUCT} element={<Routers.SuperAdminProductPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_PRODUCT} element={<Routers.SuperAdminAddProductPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_MODULE} element={<Routers.SuperAdminModulePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_MODULE} element={<Routers.SuperAdminAddModulePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ROLES} element={<Routers.SuperAdminRolesPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_ROLES} element={<Routers.SuperAdminAddRolesPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_LICENCE} element={<Routers.SuperAdminLicencePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LICENCE} element={<Routers.SuperAdminAddLicencePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_USER_MANAGEMENT} element={<Routers.SuperAdminUserManagementPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_USER} element={<Routers.SuperAdminAddUserPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_GROUP_MANAGEMENT} element={<Routers.SuperAdminGroupManagementPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_WORKFLOW} element={<Routers.SuperAdminWorkflowPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_WORKFLOW} element={<Routers.SuperAdminAddWorkflowPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_MAIL_CONFIG} element={<Routers.SuperAdminMailConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_MAIL_CONFIG} element={<Routers.SuperAdminAddMailConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_WHATSAPP_CONFIG} element={<Routers.SuperAdminWhatsappConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_WHATSAPP_CONFIG} element={<Routers.SuperAdminAddWhatsappConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_TEXT_MSG_CONFIG} element={<Routers.SuperAdminTextMsgConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_TEXT_MSG_CONFIG} element={<Routers.SuperAdminAddTextMsgConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_DOCUMENT_CONFIG} element={<Routers.SuperAdminDocumentConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_DOCUMENT_CONFIG} element={<Routers.SuperAdminAddDocumentConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_CREDIT_ASSESSMENT_CONFIG} element={<Routers.SuperAdminCreditAssessmentConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_CREDIT_ASSESSMENT_CONFIG} element={<Routers.SuperAdminAddCreditAssessmentConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_LENDER_PAGE} element={<Routers.SuperAdminLenderPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_LENDER_DETAIL_PAGE} element={<Routers.SuperAdminLendersDetailPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LENDER_PERSONAL} element={<Routers.SuperAdminAddLenderPersonalPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LENDER_ADDRESS} element={<Routers.SuperAdminAddLenderAddressPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LENDER_BANK} element={<Routers.SuperAdminAddLenderBankPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_LENDER_DOCUMENT} element={<Routers.SuperAdminAddLenderDocumentPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_MAIL_TEMPLATE_CONFIG} element={<Routers.SuperAdminMailTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_MAIL_TEMPLATE_CONFIG} element={<Routers.SuperAdminAddMailTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_WHATSAPP_TEMPLATE_CONFIG} element={<Routers.SuperAdminWhatsappTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_WHATSAPP_TEMPLATE_CONFIG} element={<Routers.SuperAdminAddWhatsappTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_TEXT_MSG_TEMPLATE_CONFIG} element={<Routers.SuperAdminTextMsgTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_TEXT_MSG_TEMPLATE_CONFIG} element={<Routers.SuperAdminAddTextMsgTemplateConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_COMMUNITY_PAGE} element={<Routers.SuperAdminCommunityPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_COMMUNITY_DETAIL_PAGE} element={<Routers.SuperAdminCommunityDetailPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_PERSONAL} element={<Routers.SuperAdminAddCommunityPersonalPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_PROFESSIONAL} element={<Routers.SuperAdminAddCommunityProfessionalPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_ADDRESS} element={<Routers.SuperAdminAddCommunityAddressPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_BANK} element={<Routers.SuperAdminAddCommunityBankPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_COMMUNITY_DOCUMENT} element={<Routers.SuperAdminAddCommunityDocumentPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_CONTACT_PAGE} element={<Routers.SuperAdminContactPage />} />
            
            <Route path={OurRoutes.SUPER_ADMIN_ADD_BLOG} element={<Routers.SuperAdminAddBlog />} />
            <Route path={OurRoutes.SUPER_ADMIN_BLOG_CONFIG} element={<Routers.SuperAdminBlogConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_BLOG_DETAILS} element={<Routers.SuperAdminBlogDetails />} />


            <Route path={OurRoutes.SUPER_ADMIN_ESIGN_CONFIG} element={<Routers.SuperAdminEsignConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_ESIGN_CONFIG} element={<Routers.SuperAdminAddEsignConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ENASH_CONFIG} element={<Routers.SuperAdminEnashConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_ENASH_CONFIG} element={<Routers.SuperAdminAddEnashConfigPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_STAGE} element={<Routers.SuperAdminStagePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_STAGE} element={<Routers.SuperAdminAddStagePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_DOC_TEMPLATE} element={<Routers.SuperAdminDocTemplatePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_DOC_TEMPLATE} element={<Routers.SuperAdminAddDocTemplatePage />} />
            <Route path={OurRoutes.SUPER_ADMIN_DOWNLOAD_SHEET} element={<Routers.SuperAdminDownloadSheetPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ALL_LEADS} element={<Routers.SuperAdminAllLeadsPage />} />

            {/* //changess */}
            <Route path={OurRoutes.Anonymous_call} element={<Routers.SuperAdminAnonymouscall />} />
            <Route path={OurRoutes.Loan_Requirement} element={<Routers.SuperAdminLeadsLoanRequirement />} />



            {/* till here */}
            <Route path={OurRoutes.SUPER_ADMIN_LEAD_DETAIL} element={<Routers.SuperAdminLeadDetailPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_REWARDS_CONFIG} element={<Routers.SuperAdminRewardsConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_REWARDS_CONFIG_TABLE} element={<Routers.SuperAdminRewardsConfigTable />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_REWARDS_CONFIG} element={<Routers.SuperAdminAddRewardsConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_NBFC} element={<Routers.SuperAdminNbfcConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_NBFC} element={<Routers.SuperAdminAddNbfcConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_BULK_NBFC} element={<Routers.SuperAdminAddBulkNbfc />} />
            <Route path={OurRoutes.SUPER_ADMIN_NBFC_INTERACTION} element={<Routers.SuperAdminNbfcInteraction />} />

            <Route path={OurRoutes.SUPER_ADMIN_RM_CONFIG} element={<Routers.SuperAdminRMConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_RM_DETAILS} element={<Routers.SuperAdminAddRM />} />



            {/* opportunity start */}
            <Route path={OurRoutes.SUPER_ADMIN_OPPORTUNITIES} element={<Routers.SuperAdminAllOpportunityPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_OPPORTUNITIES_DETAILS} element={<Routers.SuperAdminOpportunityDetailPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_PERSONAL} element={<Routers.SuperAdminOpportunityEdit />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_BUSINESS} element={<Routers.SuperAdminOpportunityBusinessPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_SALARIED} element={<Routers.SuperAdminOpportunitysalaryPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_PROPERTY} element={<Routers.SuperAdminOpportunityPropertyPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_DIRECTOR} element={<Routers.SuperAdminOpportunityDirectorPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_ADDRESS} element={<Routers.SuperAdminOpportunityAddressPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_BANK} element={<Routers.SuperAdminOpportunityBankPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_LOAN} element={<Routers.SuperAdminOpportunityLoanPage />} />
            <Route path={OurRoutes.ADMIN_OPPORTUNITY_DOCUMENT} element={<Routers.SuperAdminOpportunityDocumentPage />} />

            <Route path={OurRoutes.SUPER_ADMIN_ADD_PAYLOAD} element={<Routers.SuperAdminAddPayload />} />
            <Route path={OurRoutes.SUPER_ADMIN_PAYLOAD_CONFIG} element={<Routers.SuperAdminPayloadConfig />} />
            {/* opportunity end */}
            {/* Chapters */}
            <Route path={OurRoutes.SUPER_ADMIN_ADD_CHAPTER_CONFIG} element={<Routers.AddChapterConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_CHAPTER_CONFIG} element={<Routers.ChapterConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_CHAPTER_CONFIG_DETAILS} element={<Routers.ChapterInfo />} />
            <Route path={OurRoutes.SUPER_ADMIN_RM_INFO} element={<Routers.RMiClick />} />


            <Route path={OurRoutes.SUPER_ADMIN_OPPORTUNITIES} element={<Routers.SuperAdminOpportunityConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_OPPORTUNITIES} element={<Routers.SuperAdminAddOpportunity />} />

            <Route path={OurRoutes.SUPER_ADMIN_BANK_CONFIG} element={<Routers.SuperAdminBankConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_BANK_CONFIG} element={<Routers.SuperAdminAddBankConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_BANK_INTERACTION} element={<Routers.SuperAdminBankInteraction />} />

            <Route path={OurRoutes.SUPER_ADMIN_BULK_ADD_BANK_CONFIG} element={<Routers.SuperAdminBulkAddBankConfig />} />
            <Route path={OurRoutes.THIRD_PARTY_KYC_ADMIN} element={<Routers.SuperAdminKyc />} />
            
            <Route path={OurRoutes.THIRD_PARTY_ADD_CRIF} element={<Routers.ThirdPartyAddCrif />} />
            <Route path={OurRoutes.THIRD_PARTY_CRIF_DETAILS} element={<Routers.ThirdPartyCrifConfig />} />

            <Route path={OurRoutes.KARZA_AADHAAR_INFO} element={<Routers.KarzaAadhaarInfo />} />
            <Route path={OurRoutes.KARZA_PAN_INFO} element={<Routers.KarzaPanInfo />} />

            <Route path={OurRoutes.GST_PORTAL_ADMIN} element={<Routers.SuperAdminGST />} />
            <Route path={OurRoutes.GST_ADMIN_INFO} element={<Routers.GSTAdminInfo />} />

            {/* Enterprise Data */}
            <Route path={OurRoutes.SUPER_ADMIN_ENTERPRISE_DATA_CONFIG} element={<Routers.SuperAdminEnterpriseConfig />} />
            <Route path={OurRoutes.SUPER_ADMIN_ENTERPRISE_DATA_ADD} element={<Routers.SuperAdminEnterpriseConfigAdd />} />
            <Route path={OurRoutes.SUPER_ADMIN_ENTERPRISE_DATA_BULK_UPLOAD} element={<Routers.SuperAdminEnterpriseConfigBulkUpload />} />


            <Route path={OurRoutes.ADMIN_ADD_LEAD_PERSONAL} element={<Routers.SuperAdminLeadLeadEdit />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_BUSINESS} element={<Routers.CommunityAddLeadBusinessPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_SALARIED} element={<Routers.CommunityAddLeadsalaryPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_PROPERTY} element={<Routers.CommunityAddLeadPropertyPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_DIRECTOR} element={<Routers.CommunityAddLeadDirectorPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_ADDRESS} element={<Routers.CommunityAddLeadAddressPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_BANK} element={<Routers.CommunityAddLeadBankPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_LOAN} element={<Routers.CommunityAddLeadLoanPage />} />
            <Route path={OurRoutes.ADMIN_ADD_LEAD_DOCUMENT} element={<Routers.CommunityAddLeadDocumentPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_NEWS} element={<Routers.SuperAdminAddNewsPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_NEWS} element={<Routers.SuperAdminNewsPage />} />
            <Route path={OurRoutes.SUPER_ADMIN_BANK_CARDS} element={<Routers.SuperAdminBanks />} />
            <Route path={OurRoutes.SUPER_ADMIN_QR_LISTING} element={<Routers.SuperQrListing />} />
            <Route path={OurRoutes.SUPER_ADMIN_ADD_QR} element={<Routers.SuperAdminAddQr />} />
            <Route path={OurRoutes.SUPER_ADMIN_QR_DETAILS} element={<Routers.SUPER_ADMIN_QR_DETAILS />} />
            <Route path={OurRoutes.ADMIN_BULK_COMMUNICATION_HISTORY} element={<Routers.ADMIN_BULK_COMMUNICATION_HISTORY />} />
            <Route path={OurRoutes.SUPER_ADMIN_CHAPTER_DASHBOARD} element={<Routers.SUPER_ADMIN_CHAPTER_DASHBOARD />} />

            <Route path={OurRoutes.ADMIN_CALCULATOR} element={<Routers.AdminCalculator />} />
            <Route path={OurRoutes.ADMIN_QUERY} element={<Routers.CommunityQueries />} />

            <Route element={<Routers.SUPER_ADMIN_PRODUCTIVITY />}>
              <Route path={OurRoutes.SUPER_ADMIN_ROADMAP} element={<Routers.SUPER_ADMIN_ROADMAP />} ></Route>

            </Route>
          </Route>

          {/* community pages */}
          <Route path={OurRoutes.COMMUNITY_LOGIN} element={<Routers.CommunityLoginPage />} />
          <Route element={<Routers.CommunityDashboardLayoutPage />}>
            <Route path={OurRoutes.COMMUNITY_DASHBOARD} element={<Routers.CommunityDashboardPage />} />
            <Route path={OurRoutes.COMMUNITY_ALL_LEADS} element={<Routers.CommunityAllLeadsPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_PERSONAL} element={<Routers.CommunityAddLeadPersonalPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_BUSINESS} element={<Routers.CommunityAddLeadBusinessPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_SALARIED} element={<Routers.CommunityAddLeadsalaryPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_PROPERTY} element={<Routers.CommunityAddLeadPropertyPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_DIRECTOR} element={<Routers.CommunityAddLeadDirectorPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_ADDRESS} element={<Routers.CommunityAddLeadAddressPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_BANK} element={<Routers.CommunityAddLeadBankPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_LOAN} element={<Routers.CommunityAddLeadLoanPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_LEAD_DOCUMENT} element={<Routers.CommunityAddLeadDocumentPage />} />
            <Route path={OurRoutes.COMMUNITY_LEAD_DETAIL} element={<Routers.CommunityLeadDetailPage />} />
            <Route path={OurRoutes.COMMUNITY_BULK_COMMUNICATION} element={<Routers.BulkCommunicationPage />} />
            <Route path={OurRoutes.CRIF} element={<Routers.CrifPage />} />
            <Route path={OurRoutes.COMMUNITY_COMMUNITY_PAGE} element={<Routers.CommunityCommunityPage />} />
            <Route path={OurRoutes.COMMUNITY_COMMUNITY_DETAIL_PAGE} element={<Routers.CommunityCommunityDetailPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_PERSONAL} element={<Routers.CommunityAddCommunityPersonalPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_PROFESSIONAL} element={<Routers.CommunityAddCommunityProfessionalPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_ADDRESS} element={<Routers.CommunityAddCommunityAddressPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_BANK} element={<Routers.CommunityAddCommunityBankPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_COMMUNITY_DOCUMENT} element={<Routers.CommunityAddCommunityDocumentPage />} />
            <Route path={OurRoutes.COMMUNITY_BULK_COMMUNICATION_HISTORY} element={<Routers.BulkCommunicationHistoryPage />} />
            <Route path={OurRoutes.COMMUNITY_PROFILE_DETAILS} element={<Routers.CommunityProfileDetails />} />
            <Route path={OurRoutes.COMMUNITY_QUERY} element={<Routers.CommunityQueries />} />

            <Route path={OurRoutes.EDIT_PROFILE} element={<Routers.CommunityProfileDetailsEdit />} />
            <Route path={OurRoutes.COMMUNITY_LEDGER_LEADS} element={<Routers.CommunityLedgerLeads />} />
            <Route path={OurRoutes.COMMUNITY_GENERATE_REPORT} element={<Routers.CommunityGenerateReport />} />
            <Route path={OurRoutes.COMMUNITY_FORUMS} element={<Routers.CommunityForumsCreate />} />
            <Route path={OurRoutes.COMMUNITY_ALL_FORUMS} element={<Routers.CommunityAllForums />} />
            <Route path={OurRoutes.COMMUNITY_FORUMS_FOR_ALL} element={<Routers.CommunityForumForAll />} />
            <Route path={OurRoutes.FORUM_DETAILS_PAGE} element={<Routers.CommunityForumDetail />} />
            <Route path={OurRoutes.COMMUNITY_CALCULATOR} element={<Routers.CommunityCalculator />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITIES} element={<Routers.CommunityAllOpportunityPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITIES_DETAILS} element={<Routers.CommunityOpportunityDetailPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_PERSONAL} element={<Routers.CommunityOpportunityEdit />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_BUSINESS} element={<Routers.CommunityOpportunityBusinessPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_SALARIED} element={<Routers.CommunityOpportunitysalaryPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_PROPERTY} element={<Routers.CommunityOpportunityPropertyPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_DIRECTOR} element={<Routers.CommunityOpportunityDirectorPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_ADDRESS} element={<Routers.CommunityOpportunityAddressPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_BANK} element={<Routers.CommunityOpportunityBankPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_LOAN} element={<Routers.CommunityOpportunityLoanPage />} />
            <Route path={OurRoutes.COMMUNITY_OPPORTUNITY_DOCUMENT} element={<Routers.CommunityOpportunityDocumentPage />} />
            <Route path={OurRoutes.COMMUNITY_ADD_OPPORTUNITIES} element={<Routers.CommunityAddOpportunity />} />

          </Route>

          {/* lender pages */}
          <Route path={OurRoutes.LENDER_LOGIN} element={<Routers.LenderLoginPage />} />
          <Route element={<Routers.LenderDashboardLayoutPage />}>
            <Route path={OurRoutes.LENDER_DASHBOARD} element={<Routers.LenderDashboardPage />} />
            <Route path={OurRoutes.LENDER_ALL_LEADS} element={<Routers.LenderAllLeadsPage />} />
            <Route path={OurRoutes.LENDER_LEAD_DETAIL} element={<Routers.LenderLeadDetailPage />} />
            <Route path={OurRoutes.LENDER_BULK_COMMUNICATION} element={<Routers.BulkCommunicationPage />} />
            <Route path={OurRoutes.LENDER_BULK_COMMUNICATION_HISTORY} element={<Routers.BulkCommunicationHistoryPage />} />
            <Route path={OurRoutes.LENDER_ADD_EMPLOYEE} element={<Routers.addEmployeePage />} />
            <Route path={OurRoutes.LENDER_EMPLOYEE_LIST} element={<Routers.employeePage />} />
            <Route path={OurRoutes.LENDER_GENERATE_REPORT} element={<Routers.LenderGenerateReport />} />
            <Route path={OurRoutes.LENDER_CALCULATOR} element={<Routers.LenderCalculator />} />
            <Route path={OurRoutes.LENDER_INCOMING_LEADS} element={<Routers.LenderIncommingleads />} />


          </Route>

          {/* external community create */}
          <Route element={<Routers.ExternalCommunityLayoutPage />}>
            <Route path={OurRoutes.EXTERNAL_ADD_COMMUNITY_PERSONAL} element={<Routers.ExternalAddCommunityPersonalPage />} />
            <Route path={OurRoutes.EXTERNAL_ADD_COMMUNITY_PROFESSIONAL} element={<Routers.ExternalAddCommunityProfessionalPage />} />
            <Route path={OurRoutes.EXTERNAL_ADD_COMMUNITY_ADDRESS} element={<Routers.ExternalAddCommunityAddressPage />} />
            <Route path={OurRoutes.EXTERNAL_ADD_COMMUNITY_BANK} element={<Routers.ExternalAddCommunityBankPage />} />
            <Route path={OurRoutes.EXTERNAL_ADD_COMMUNITY_DOCUMENT} element={<Routers.ExternalAddCommunityDocumentPage />} />
            <Route path={OurRoutes.EXTERNAL_UPLOAD_ONLY_DOCUMENTS_COMMUNITY} element={<Routers.ExternalUploadDocumentPage />} />

            <Route path={OurRoutes.EXTERNAL_ADD_LOAN_DETAILS} element={<Routers.ExternalLoanDetails />} />

            {/* Add community Leads external */}
            <Route path={OurRoutes.EXTERNAL_COMMUNITY_ADD_LEAD_PERSONAL} element={<Routers.CommunityAddLeadPersonalPage />} />
            <Route path={OurRoutes.EXTERNAL_COMMUNITY_ADD_LEAD_BUSINESS} element={<Routers.CommunityAddLeadBusinessPage />} />
            <Route path={OurRoutes.EXTERNAL_COMMUNITY_ADD_LEAD_SALARIED} element={<Routers.CommunityAddLeadsalaryPage />} />
            <Route path={OurRoutes.EXTERNAL_COMMUNITY_ADD_LEAD_ADDRESS} element={<Routers.CommunityAddLeadAddressPage />} />

            <Route path={OurRoutes.EXTERNAL_COMMUNITY_ADD_LEAD_PROPERTY} element={<Routers.CommunityAddLeadPropertyPage />} />
            <Route path={OurRoutes.EXTERNAL_COMMUNITY_ADD_LEAD_DIRECTOR} element={<Routers.CommunityAddLeadDirectorPage />} />
            <Route path={OurRoutes.EXTERNAL_COMMUNITY_ADD_LEAD_DOCUMENT} element={<Routers.CommunityAddLeadDocumentPage />} />
            <Route path={OurRoutes.EXTERNAL_COMMUNITY_ADD_LEAD_LOAN} element={<Routers.CommunityAddLeadLoanPage />} />


          </Route>
          <Route path={OurRoutes.RESET_PASSWORD} element={<Routers.ResetPassword />} />
          <Route path={OurRoutes.EXTERNAL_LEADS_DETAILS} element={<Routers.ExternalLeadsDetailsPage />} />

          <Route path={OurRoutes.TEST_AUDIO} element={<Routers.TestAudio />} />

          <Route path={OurRoutes.SUPER_ADMIN_CHAPTER_PARTICULAR_CHAPTER} element={<Routers.SUPER_ADMIN_CHAPTER_PARTICULAR_CHAPTER />} />

{/* chnages  */}

          

        </Routes>
      </Suspense>
    </Router>
  )
}

export default AppRouter;
